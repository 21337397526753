// 一般接口请求地址
  let baseURL = window.location.protocol+"//"+window.location.host+"/forex/"
//  let baseURL = window.location.protocol+"//"+window.location.host+"/"
// let baseURL = window.location.protocol+"//app8.localhost/"
// let baseURL = window.location.protocol+"//b.jumpsea.link/"
//
// websocket请求地址
//let wsURL = window.location.host
export {
  baseURL,
}