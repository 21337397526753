import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import i18n from './assets/i18n'
import http from './plugin/http.js'
import store from './store/index.js'
//import tmpl from './plugin/template.js'
//import BootstrapVue3  from 'bootstrap-vue-3'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
//import 'bootstrap/dist/css/bootstrap.css'
// import "@/assets/css/variable.less"
// import "@/assets/css/style.css"
//import "bootstrap/dist/js/bootstrap.min.js";
//import VueClipboard from 'vue3-clipboard'
// import '@/assets/css/style3.css';

//import { Collapse, CollapseItem } from 'vant';
//import { Icon } from 'vant';
//import 'vant/lib/index.css'
// import "@/assets/css/exchange.less"
// import "@/assets/css/exchange.css"
// import "@/assets/css/trade-history.css"

//import BaseButton from '@/components/UI/BaseButton.vue'
//import BaseCard from '@/components/UI/BaseCard.vue'
//import Toaster from "@meforma/vue-toaster";
// import BaseLayout from '@/components/UI/BaseLayout.vue'
// import BaseLayout2 from '@/components/UI/BaseLayout2.vue'
//import BaseLayout3 from '@/components/UI/themes/theme1/BaseLayout3.vue'

/* Using FontAwesome Icons */
//library.add(fas, far, fab);


// import { BootstrapVueIcons } from 'bootstrap-vue'
// import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'


//import { message } from './assets/js/resetMessage.js'


const app = createApp(App);
// 请求方法挂在vue实例上
app.config.globalProperties.$http = http;
app.config.globalProperties.$cfg = process.env;
//app.config.globalProperties.$currency = "$";
//app.config.globalProperties.$code = tmpl;
//let _language = localStorage.getItem('lang');
//设置默认语言

const filterDomain = [
  "logintreats.com",
  "www.logintreats.com"
]

app.use(router)
app.use(store)


// app.use(ElementPlus)
app.mount('#app')
// app.component('base-layout', BaseLayout);
// app.component('base-layout2', BaseLayout2);
// 解决重复弹窗
//app.config.globalProperties.$message = message;

document.getElementById("app").style.maxWidth = "100%!important";




