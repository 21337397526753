import axios from 'axios'
//index.css
//import 'vant/es/toast/index.css'
//import { Toast } from "vant"
import { getLocal,dataStringify,removeLocal } from '@/utils/local.js';
import router from '@/router'
import store from '@/store/index.js'
import {baseURL} from './baseUrl'


const instance = axios.create({
	// timeout: 15000, //多少毫秒请求toa超时
	get: {
		"Content-Type": "application/x-www-form-urlencoded"
	},
	post: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
	headers: {
		// 'X-Requested-With': 'XMLHttpRequest',
		// 'api-key' : 'KfVBHY8g6R',
	},
	// withCredentials: true,
	/*
	proxy: {
		protocol: 'http',
		host: 'tk5.local',
		port: 80,
	},
	*/

})

// 添加请求拦截器
instance.interceptors.request.use((config)=>{
	// 请求头添加语言和token
	let token = getLocal('token') || ''
	config.headers.lang = localStorage.getItem("lang") || "cn";
	config.headers.token = token;
	if(config.method == "post"){
		let data = config.data;
		data.token = token;
		if(data.json){
			config.headers.post = {
				"Content-Type": "application/json"
			}
		} else {
			/* 对请求参数转化成字符串；
			比如{page: 1,pagesize: 6}转化成"page=1&pagesize=6";
			请求参数转化这样后，请求就会变成Form Data
			*/
			if(data instanceof FormData){
				config.post = {
					"Content-Type": "multipart/form-data"
				}
			}else{
				config.transformRequest = (data)=>{
					return dataStringify(data)
				}
			}
		}
	}
	return config
},(error)=>{
		console.log('eer');
	return Promise.reject(error);
})

// 修改登录提示
const tips = {
	en: "Please login to continue operation",
	cn: "请登录后再操作",
	my: "Sila log masuk dan kemudian ambil tindakan",
}

// 添加响应拦截器
instance.interceptors.response.use((res)=>{
	console.log('intercept');
	console.log(res);
	// 请求返回code为401是token失效，需要重新登录
	var path        = window.location.pathname;

         if(res.data && res.data.code == "111" ){
            var $split = (location.href.split('#'));
            var $split2 = $split[1].split('?');
             if (typeof $split2[0] !="undefined" && $split2[0] != '/') {
                path = $split2[0];
             }
          }

        var redirect = false ;
	
        if (process.env.VUE_APP_ROUTE_ALLOW_MARKET == '1') {
            if(res.data && res.data.code == "111" && path != '/' && path != '/login' && path != '/register' && path != '/market' && path != '/trade/exchange' ){ 
                redirect = true;
            }
        }
        else {
            if(res.data && res.data.code == "111" && path != '/login' && path != '/register' && path != '/account/language'){ 
                redirect = true;
            }
        }
        
	//if(res.data && res.data.code == "111" && path != '/' && path != '/login' && path != '/register' && path != '/market' && path != '/trade/exchange' ){
        //if(res.data && res.data.code == "111" && path != '/login' && path != '/register' && path != '/account/language'){
	if(redirect === true){
		removeLocal('token');
		let lang = getLocal('lang') || "cn";
		//Toast(tips[lang]);
		setTimeout(() => {
			store.commit("deleteSession")
			router.push("/login")
		}, 500)
		return;

	}
	return res.data
},(error)=>{
	return Promise.reject(error);
})

// prot 端口号；url 请求地址；params 请求参数；method 请求方法，默认post
const http = (port,url,params,method)=>{
	//url = `${baseURL}${port}${url}`
	url = `${baseURL}${port}${url}`
	params = params || {}
	method = method || "post"
	let lang = getLocal('lang') || "cn";
	let token = getLocal('token') || "";
	let obj = {};
	let merge_data = {
		lang  : lang,
		time  : Date.now(),
		token :  token
	};
	if(method == "post"){
		obj = {
			url,
			data: params,
			params : {
				...merge_data,
			},
			method,
		}
	}
	else if(method == "postform"){
		obj = {
			url,
			data: params,
			params : {
				...merge_data,
			},
			method :'post',
			headers: { "Content-Type": "multipart/form-data" },
		}
	} else {
		obj = {
			url,
			params : {
				...params,
				...merge_data,
			},
			method,
		}
	}
	// console.log(url)
	return new Promise((resolve,reject)=>{
		instance(obj)
		.then(function(res){
				resolve(res)
			})
			.catch((err)=>{
				console.log(err);
				reject(err)
			})
	})

}

export default http




